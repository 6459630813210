import { HttpClient } from '@angular/common/http';
import { effect, inject, Injectable, signal } from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { User } from '../../../user/interfaces/zitadel-user.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  private readonly userService = inject(UserService);

  readonly users = signal<User[]>(new Array());

  loadUsersToSystem() {
    this.userService.fetchUsers().subscribe({
      next: (value) => {
        this.users.update(() => value.result);
        console.log(value);
      }
    })
  }
}
